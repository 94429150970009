import { StaticQuery, graphql } from 'gatsby';
import React from 'react';
import { Card } from 'reactstrap';

import { Link, SEO } from '@components';
import { BlogEntryProps, LinkType } from '@types';

import './index.scss';

const Atlantis = ({ includeSeo }: BlogEntryProps) => (
  <StaticQuery
    query={graphql`
      query {
        cover: file(name: {eq: "AtlantisCoverLong"}) {
          publicURL
        }
        demo: file(name: {eq: "AtlantisDemo"}) {
          publicURL
        }
      }
    `}
    render={({ cover, demo }) => (
      <>
        {includeSeo && (
          <SEO
            description={'My first single "Atlantis" is out now!'}
            image={cover.publicURL}
            slug="atlantis"
            title="Atlantis - RadiumTree"
          />
        )}
        <Card className="atlantis px-0">
          <img
            alt="Album artwork for Atlantis depicting a shady, hacker guy riding a dolphin"
            className="d-block mb-2 mx-auto"
            src={cover.publicURL}
          />
          <h2 className="mb-0 mt-3 text-center">Atlantis</h2>
          <span className="mb-3 text-center">10 November 2023</span>
          <div className="body mx-auto px-2 px-sm-0">
            <p>
              I&apos;m so happy to finally release this song into the world. It&apos;s fruition has taken me along for quite the journey. In fact, it&apos;s hard to believe I even got it to the finish line as I have been dabbling with it since 2011. I remember it like it was yesterday; there I was in my electronic audio composition class tasked with writing a beat containing three elements: a lead, a bass, and drums. It was my first time ever doing something with MIDI and has informed my production process every step of the way since.
            </p>
            <div className="audio-container">
              <audio controls>
                <source src={demo.publicURL} type="audio/mpeg" />
                <track kind="captions" />
              </audio>
              <small>Atlantis Demo (circa 2011)</small>
            </div>
            <p>
              IIRC, the sound I used for the lead was a sample of the Akai AX80 preset (which I still want some day), the bass was some random 808, and the drums had a kick, a snap, and shaker, all of which I found on the school hard drives. Most of those early elements made it into the end result, but the production has been all over the place. It moved from Reason to FL Studio to the MPC and finally to ProTools. As usual, lyrics took FOREVERRRRRRRRRRRRRRRR; my only hope is that they get across what I am trying to express, but only you can be the judge of that. <Link type={LinkType.INSTAGRAM_DM}>DM me on instagram</Link> and tell me what you think; I would love to know.
            </p>
            <hr />
          </div>
        </Card>
      </>
    )}
  />
);

export default Atlantis;
